<template>
  <div id="main-page">
    <!-- 头部 -->
    <Header :now-index="nowIndex"
            :go-anchor="goAnchor" />
    <a href="javascript:;"
       class="shop"
       :class="['type'+[adTest]]"
       @click="download('shop','https://pay.acngames.com/#/game/15')">
    </a>
    <full-page ref="fullpage"
               id="fullpage"
               :options="options.fullpage">
      <div class="section page1" :class="['type'+[adTest]]">
        <div class="container">
          <div class="slogan">
            <!-- <img class="video-img" @click="videobtn"  src="@/assets/desktop/home/1/video.png" alt="视频播放"> -->
            <div class="download" :class="['type'+[adTest]]">
              <a a
                 href="javascript:;"
                 class="android"
                 @click="download('APK','https://wydxz.onelink.me/i52G/cdiqe3eq')">
              </a>
              <a href="javascript:;"
                 class="ios"
                 @click="download('ios','https://trueviewforios.onelink.me/nAvK/ap06hk76')">
              </a>
            </div>
            <!-- <p  class="activityTime"
               data-text='儲值活動時間： 2024年03月30日-2024年04月05日'>儲值活動時間： 2024年03月30日-2024年04月05日</p> -->
          </div>
        </div>
      </div>
      <!-- 游戏特色 -->
      <div class="section page5"  :class="['type'+[adTest]]">
        <div class="container">
          <div class="content">
            <img class="title"
                 src="@/assets/desktop/home/5/title5.png"
                 alt="游戏特色">
            <div class="characteristics">
              <swiper class="swiper characteristicsGame"
                      ref="gameSwiper"
                      :options="swiperGameOption">
                <swiper-slide class="characteristicsGameItem"
                              v-for="item in swiperData.game"
                              :key="item">
                  <template v-if="adTest !=='3'">
                    <img class="gameinfo"
                        :src="require(`@/assets/desktop/home/5/gameinfo_${item}.png`)">
                    <img class="game"
                        :src="require(`@/assets/desktop/home/5/game_${item}.png`)">
                  </template>
                  <template v-else>
                  <img class="gameinfo"
                      :src="require(`@/assets/desktop/home/5/gameinfo_${item}_.png`)">
                  <img class="game"
                      :src="require(`@/assets/desktop/home/5/game_${item}_.png`)">
                  </template>
                </swiper-slide>
              </swiper>
              <!-- <swiper class="swiper characteristicsGame"
                      ref="gameSwiper"
                      :options="swiperGameOption">
                <swiper-slide class="characteristicsGameItem"
                              v-for="item in swiperData.game1"
                              :key="item">
                  <img class="game"
                       :src="require(`@/assets/desktop/home/5/game_${item}_.png`)">
                </swiper-slide>
              </swiper> -->
              <!-- 左右箭头 -->
              <div class="swiper-button-prev"
                   @click='swiperGamePrev'></div>
              <div class="swiper-button-next"
                   @click='swiperGameNext'></div>
            </div>
          </div>
          <!--底部-->
          <div id="footer">
            <div class="footer-content">
              <!-- <div class="foot-left">
                <img class="acn" src="@/assets/desktop/footer/acn.png" alt="左边logo">
                <div class="title-content">
                  <div class="title">Copyright © 2021 ACNGames.com</div>
                  <div>
                    <a href="javascript:;" @click="goToLink(1)">隱私協議</a>
                    <div class="title-middle">｜</div>
                    <a class="email" href="mailto:wydxzcs@gmail.com">聯繫客服</a>
                  </div>
                </div>
              </div>
              <div class="long-string"></div> -->
              <!-- <img class="text-img" src="@/assets/desktop/footer/text.png" alt="文字"> -->
            </div>
          </div>
        </div>
      </div>
    </full-page>
    <!-- 视频弹窗 -->
    <!-- 视频弹窗 -->
    <div class="ytb_video_bg"
         v-if="showVideo"
         @click="doShowVideo">
      <iframe class="ytb_video"
              src="https://www.youtube.com/embed/IQzITvqIw3I?&autoplay=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
import Header from './header'
// import { appointRecordDown } from '@/api'
import { getUrlParam } from '@/utils/index'
import { mapState, mapMutations } from 'vuex'
export default {
  components: { Header },
  created () {
  },
  mounted () {

  },
  computed: {
    ...mapState({
      token: state => state.token,
      supportId: state => state.supportId
    })
  },
  data () {
    const self = this
    return {
      adTest: getUrlParam('abtest') || '2',
      canvas: '',
      ctx: '',
      ballNum: '', // 小球个数
      ballcolor: 1,
      awardList: [],
      scale: 1,
      imgSrcWh: '', // 小球尺寸
      isAppointment: false, // 是否预约
      nowIndex: 0,
      drawTop: true, // 立即抽奖
      drawNum: 1, // 抽奖次数
      shareFacebookTop: true, // 抽奖分享facebook
      shareLineTop: true, // 抽奖分享line
      supportShow: true, // 显示应援进度
      progressNum: [1000, 2000, 3000], // 应援数
      progressWidth: [],
      popupType: 0, // 弹窗类型
      makeNmu: '0', // 预约人数
      // supportId: 0, // 是否可以应援
      support: [], // 战队数据
      isworst: false, // 是否支持的战队是最差的
      best: [], // 人气最高战队
      options: {
        feature: {
          loop: true,
          // slidesPerView: 5,
          slidesPerView: 'auto',
          loopedSlides: 5,
          slideToClickedSlide: true,
          autoplay: {
            disableOnInteraction: false,
            delay: 5000
          }
        },
        // displaySwiper: {
        //   loop: true,
        //   spaceBetween: 20
        // },
        // gameSwiper: {
        //   loop: true,
        //   spaceBetween: 20
        // },
        fullpage: {
          onLeave (index, nextIndex) {
            self.nowIndex = nextIndex.index
          }
        }
      },
      //  人物介绍
      swiperFeatureOption: {
        // 设定初始化时slide的索引
        initialSlide: 0,
        loop: true,
        slidesPerView: 5,
        slideToClickedSlide: true,
        centeredSlides: true,
        on: {
          click () {}
          // slideNextTransitionEnd (swiper) {
          //   console.log(swiper)
          // }
        }
      },
      swiperData: {
        feature: [0, 1, 2, 3, 4, 5, 6, 7],
        game: [0, 1, 2, 3, 4],
        game1: [1, 2, 3, 4]
      },
      showFigureAnimate: false,
      // 游戏特色
      swiperGameOption: {
        initialSlide: 0,
        loop: true,
        slidesPerView: 1,
        slideToClickedSlide: true
      },
      // 跳转链接
      openLink: [
        'https://line.me/ti/g2/7Ec-1t-Gsm5z4OInIaMYB5N88d2Q3Zd_spFujA?utm_source=invitation&utm_medium=link_copy&utm_campaign=default', // line群
        'http://home.hkpctimes.com/privacy.html', // 隐私政策
        'wydxzcs@gmail.com' // 客服
      ],
      showVideo: false,
      showAnactivie: true
    }
  },
  methods: {
    ...mapMutations(['SET_USER', 'UPLOAD_EVENT']),
    // 下载
    download (type, url) {
      const type1 = getUrlParam('abtest') || '1'
      if (type === 'APK') {
        if (type1 === '1') {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/8FUECM_SiOAYEIPthKUB'
          })
        } else if (type1 === '2') {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/l8KwCJzMg-AYEIPthKUB'
          })
        } else {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/qwuSCKOfmOEYEIPthKUB'
          })
        }
        this.UPLOAD_EVENT('dowload_mycard')
      }
      if (type === 'ios') {
        if (type1 === '1') {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/h71ACNLSiOAYEIPthKUB'
          })
        } else if (type1 === '2') {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/--QtCJ_Mg-AYEIPthKUB'
          })
        } else {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/h89PCKafmOEYEIPthKUB'
          })
        }
        this.UPLOAD_EVENT('dowload_appstore')
      }
      if (type === 'shop') {
        if (type1 === '1') {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/32tqCNXSiOAYEIPthKUB'
          })
        } else if (type1 === '2') {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/jYkoCKLMg-AYEIPthKUB'
          })
        } else {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/J8dTCKmfmOEYEIPthKUB'
          })
        }
        this.UPLOAD_EVENT('click_web_recharge')
      }
      setTimeout(() => {
        window.open(url)
      }, 200)
    },
    goAnchor (v) {
      this.$refs.fullpage.api.moveTo(v)
    },
    // 04人物介绍
    swiperFeaturePrev (swiper) {
      this.$refs.featureSwiper.$swiper.slidePrev()
    },
    swiperFeatureNext (swiper) {
      this.$refs.featureSwiper.$swiper.slideNext()
    },
    featureSlideChange (swiper) {
      // this.$refs.figurePort.classList.remove('figurePort-enter-active')
      // this.$refs.figureInfo.classList.remove('figureInfo-enter-active')
      this.featureIndex = swiper.realIndex
      // this.$nextTick(() => {
      //   this.$refs.figurePort.classList.add('figurePort-enter-active')
      //   this.$refs.figureInfo.classList.add('figureInfo-enter-active')
      // })
    },
    // 05游戏特色
    swiperGameNext () {
      this.$refs.gameSwiper.$swiper.slideNext()
    },
    swiperGamePrev () {
      this.$refs.gameSwiper.$swiper.slidePrev()
    },
    // 播放视频
    videobtn () {
      // this.$refs.video.dialogVisible = true
      this.showVideo = true
    },
    doShowVideo () {
      this.showVideo = false
    },
    // 点击链接
    goToLink (type) {
      if (type === 0) {
        window.gtag('event', 'conversion', {
          send_to: 'AW-346109571/AkrVCLqz064DEIPthKUBs'
        })
        this.UPLOAD_EVENT('click_to_line')
      }
      window.open(this.openLink[type])
    },
    // 去应援
    supportClick (type) {
      if (this.token) { // 登陆了就去请求战队应援接口
        this.appointSupport(type)
        // this.supportShow = false
      } else { // 未登录打开登陆框
        this.showPopup(1)
      }
    },
    // 去预约
    makeappointment () {
      // console.log(this.token)
      window.gtag('event', 'conversion', {
        send_to: 'AW-346109571/K6atCN6Y064DEIPthKUB'
      })
      window.fbq('trackCustom', 'resgister')
      this.UPLOAD_EVENT('re_register_button_click')
      if (this.token) { // 预约成功弹预约成功框
        this.showPopup(2)
      } else {
        this.showPopup(1) // 填写手机号
      }
    },
    clear () {
      setTimeout(() => {
        this.drawTop = true
        window.clearInterval(this.timer) // 清除计时器
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
        this.UPLOAD_EVENT('draw')
        this.appointRewind()
      }, 1500)
    },
    // 查看奖品
    lookprizes () {
      if (this.token) { // 预约成功弹查看奖品框
        this.showPopup(4)
        this.$refs.popupview.appointUserWinRecord()
      } else {
        this.showPopup(1) // 填写手机号
      }
    },
    // 分享
    fbshare () {
      if (this.token) {
        this.UPLOAD_EVENT('share_facebook')
        window.FB && window.FB.ui({
          method: 'share',
          // mobile_iframe: true,
          href: window.location.href
        }, function (response) { })
        this.appointAddShareRecord()
      } else {
        this.showPopup(1) // 填写手机号
      }
    },
    lineshare () {
      if (this.token) {
        window.open(`https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(window.location.href)}`)
        this.UPLOAD_EVENT('share_line')
        this.appointAddShareRecord()
      } else {
        this.showPopup(1) // 填写手机号
      }
    },
    // 弹窗展示
    showPopup (type) {
      if (type === 1) {
        this.UPLOAD_EVENT('re_register_dialog_view')
      }
      this.$refs.popupview.popupVisible = true
      if (this.$refs.popupview.popupVisible === true) {
        this.showAnactivie = false
      }
      this.popupType = type
    },
    isShow (type) {
      if (this.token) {
        this.showAnactivie = false
      } else {
        this.showAnactivie = true
      }
    },
    // 子组件传过来操作弹窗类型
    nextType (type) {
      this.popupType = type
    }
  }
}
</script>
<style >
body {
  max-width: 10rem;
  overflow-x: hidden;
  margin: 0 auto !important;
  background-color: #000;
}
</style>
<style lang="scss" scoped>
@import '@/styles/desktop/index.scss';
</style>
