<template>
   <div class="nav pic-bg">
      <div class="nav_button">
        <div class="appointment pic-bg" @click="goAnchor(1)" :class="{is_active: nowIndex === 0}"></div>
        <div class="gamefeatures pic-bg" @click="goAnchor(2)" :class="{is_active: nowIndex === 1 || nowIndex === 2}"></div>
      </div>
      <div class="nav_share">
          <img @click="goToLink(0)" src="@/assets/desktop/header/trill.png" alt="抖音">
          <img @click="goToLink(1)" src="@/assets/desktop/header/youtube.png" alt="youtube">
          <img @click="goToLink(2)" src="@/assets/desktop/header/line.png" alt="line">
          <div v-show="lineShow" class='line-pop pic-bg'>
            <img class="line_code" src="@/assets/desktop/header/line_code.png" alt="line群二维码">
            <div @click="openAlink(2)" class="line_no pic-bg"></div>
          </div>
          <img @click="goToLink(3)" src="@/assets/desktop/header/facebook.png" alt="facebook">
      </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    nowIndex: {
      type: Number,
      default: 0
    },
    goAnchor: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      lineShow: false,
      openLink: [
        'https://www.tiktok.com/@superschoolman',
        'https://www.youtube.com/channel/UCxp87Fgs47GRQwdYozXKYQA',
        'https://line.me/ti/g2/7Ec-1t-Gsm5z4OInIaMYB5N88d2Q3Zd_spFujA?utm_source=invitation&utm_medium=link_copy&utm_campaign=default',
        'https://www.facebook.com/wdxzcyd'
      ]
    }
  },
  methods: {
    ...mapMutations(['UPLOAD_EVENT']),
    goToLink (type) {
      if (!this.lineShow) {
        this.UPLOAD_EVENT('line_dialog_view')
      }
      if (type === 2) {
        this.lineShow = !this.lineShow
      } else {
        this.lineShow = false
        this.openAlink(type)
      }
    },
    // 前往Line群
    openAlink (type) {
      switch (type) {
        case 0:
          this.UPLOAD_EVENT('click_to_tiktok')
          break
        case 1:
          this.UPLOAD_EVENT('click_to_youtube')
          break
        case 2:
          // window.gtag('event', 'conversion', {
          //   send_to: 'AW-346109571/AkrVCLqz064DEIPthKUBs'
          // })
          this.UPLOAD_EVENT('click_to_line')
          break
        case 3:
          this.UPLOAD_EVENT('click_to_facebook')
          break
        default:
      }
      window.open(this.openLink[type])
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/desktop/header.scss";
</style>
